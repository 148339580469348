import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Title from "components/Title";
import htmlContent from "helpers/htmlContent";
import Image from "next/image";
import {useRouter} from "next/router";

export default function MainRoadMap() {
	const {locale} = useRouter()
	const t = {...require(`localeComponents/roadmap/${locale}.json`)}
	const renderDescription = (array) => {
		return (
			array.map((item, index) => {
				return <p key={index}>{item}</p>
			})
		)
	}
	const renderArray = (array, complete) => {
		return (
			array.map((item, index) => {
				return (
					<div className="item_box" key={index}>
						<Title>
							<h4>{htmlContent(item.title)}</h4>
							{complete && index === 0 && <span className="complete">{t.done}</span>}
						</Title>
						<div className="description_list">
							{renderDescription(item.description)}
						</div>
					</div>
				)
			})
		)
	}
	return (
		<Wrapper>
			<CenterBlock>
				<Title className="heading"><h2>{t.title}</h2></Title>
				<div className="list_block">
					<div className="glow_top"><img src="/pic/roadmap-glow.svg" alt="RoadMap Glow"/></div>
					<div className="list">
						{
							t.list.map((item, index) => {
								return (
									<div className="item" key={index}>
										<div className="content_box">
											<div className="content_list">
												{renderArray(item.array, item.complete)}
											</div>
											<Label color={item.color} className="label">{item.label}</Label>
											{item.banana && (
												<div className="banana">
													<Image src={item.banana} width={item.banana_width} height={item.banana_height} priority={true} alt={`${item.label} banana`}/>
												</div>
											)}
										</div>
										<div className="item_arrow">
											{item.arrow && <img src={item.arrow} alt={item.label}/>}
										</div>
									</div>
								)
							})
						}
					</div>
					<div className="glow_bottom"><img src="/pic/roadmap-glow.svg" alt="RoadMap Glow Bottom"/></div>
				</div>
			</CenterBlock>
		</Wrapper>
	)
}

const Label = styled.div`
	background: ${p => p.color};
	display: inline-flex;
	border-radius: 8px;
	height: 38px;
	padding: 8px 20px;
	margin-top: 20px;
	font-size: 16px;
	line-height: 140%;
	font-weight: bold;
`

const Wrapper = styled.section`
	margin-top: 120px;
	overflow: hidden;
	@media only screen and (max-width: 600px) {
		margin-top: 70px;
	}
	.center_block{
		position: initial;
	}
	.banana{
		position: absolute;
		@media only screen and (max-width: 600px) {
			display: none;
		}
	}
	.list_block{
		position: relative;
	}
	.complete{
		position: absolute;
		top: -15px;
		right: -20px;
		background: #43C22E;
		border-radius: 8px 8px 8px 0;
		font-size: 12px;
		color: #ffffff;
		width: 45px;
		font-weight: 500;
		height: 17px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.title{
		display: inline-flex;
	}
	.glow_top{
		position: absolute;
		top: -300px;
		right: -500px;
	}
	.glow_bottom{
		position: absolute;
		bottom: 300px;
		left: -500px;
	}
	.heading{
		text-align: center;
	}
	.list{
		margin-top: 60px;
		position: relative;
		z-index: 10;
	}
	.item_box{
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 2px solid #3D3D55;
		&:last-child{
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: none;
		}
	}
	.content_box{
		width: calc(50% - 20px);
		background: rgba(19, 19, 35, 0.6);
		backdrop-filter: blur(30px);
		padding: 30px;
		border-radius: 36px;
		position: relative;
		z-index: 10;
		@media only screen and (max-width: 600px) {
			width: 100%;
		}
	}
	.item_arrow{
		width: calc(50% + 20px);
		display: flex;
		justify-content: flex-start;
		align-items: flex-start;
		@media only screen and (max-width: 600px) {
			display: none;
		}
		img{
			line-height: 0;
		}
	}
	.item {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		position: relative;
		@media only screen and (max-width: 600px) {
			margin-bottom: 70px;
			&:after{
				content: url("/pic/road-map-arrow-mob.svg");
				position: absolute;
				bottom: -70px;
				left: 50%;
				transform: translateX(-50%);
			}
			&:last-child{
				margin-bottom: 0;
			}
		}
		&:first-child{
			align-items: flex-end;
			.banana{
				left: -100px;
				bottom: -80px;
			}
		}
		&:nth-child(2) .banana{
			right: -25px;
			bottom: -30px;
		}
		&:nth-child(3) .banana{
			right: 10px;
			bottom: -50px;
		}
		&:nth-child(7) .banana{
			top: -90px;
			left: 0;
		}
		&:nth-child(10) .banana{
			top: -120px;
			left: -50px;
		}
		&:nth-child(even){
			flex-direction: row-reverse;
			.item_arrow{
				justify-content: flex-end;
				
			}
		}
		.item_box_position {
			display: flex;
			justify-content: space-between;
		}
	}
	.description_list{
		font-size: 16px;
		line-height: 140%;
		margin-top: 10px;
		color: #CFCFD2;
		p{
			margin-bottom: 20px;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
`