import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import {useRouter} from "next/router";
import FaqBlock from "components/FaqBlock";

export default function MainFaq() {
	const {locale} = useRouter()
	const array = [...require(`localeComponents/faq/${locale}.json`)]
	return (
		<Wrapper>
			<CenterBlock width={960}>
				<FaqBlock array={array}/>
			</CenterBlock>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	margin-top: 120px;
	position: relative;
	z-index: 10;
	padding-bottom: 60px;
`
