import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";

export default function Title({children, className}){
	return (
		<Wrapper className={`title ${className ? className : ""}`}>
			{children}
		</Wrapper>
	)
}

const Wrapper = styled.div`
	position: relative;
	span{
		color: #E1001C;
	}
	h1{
		font-size: 80px;
		line-height: 140%;
		@media only screen and (max-width: 768px) {
			font-size: 60px;
		}
		@media only screen and (max-width: 600px) {
			font-size: 40px;
			line-height: 116%;
		}
	}
	h2{
		font-size: 60px;
		line-height: 140%;
		@media only screen and (max-width: 600px) {
			font-size: 30px;
			line-height: 120%;
		}
	}
	h3{
		font-size: 40px;
		line-height: 140%;
	}
	h4{
		font-size: 24px;
		line-height: 140%;
	}
`
