import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Title from "components/Title";
import array from "public/data/partners.json"

export default function MainPartners({t}) {
	return (
		<Wrapper>
			<CenterBlock>
				<Title><h2>{t.title}</h2></Title>
				<div className="list">
					{
						array.map((item, index) => {
							return (
								<div className="item" key={index}>
									<img src={item.icon} alt={item.title}/>
									<p>{item.title}</p>
								</div>
							)
						})
					}
				</div>
			</CenterBlock>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	margin-top: 60px;
	.title{
		text-align: center;
	}
	.list{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		text-align: center;
		margin-top: 45px;
		@media only screen and (max-width: 820px) {
			flex-wrap: nowrap;
			overflow-x: auto;
			margin-right: -20px;
			margin-left: -20px;
			margin-bottom: -20px;
			padding: 0 20px 20px 20px;
		}
	}
	p{
		color: #CECED1;
		font-size: 18px;
		line-height: 140%;
		margin-top: 20px;
		@media only screen and (max-width: 600px) {
			font-size: 16px;
			line-height: 140%;
		}
	}
	.item{
		@media only screen and (max-width: 820px) {
			width: 140px;
			flex: 0 0 auto;
			margin-right: 20px;
			padding: 0 20px;
		}
		@media only screen and (max-width: 600px) {
			width: 90px;
		}
	}
`