import Seo from "helpers/Seo";
import Head from "next/head";
import MainBlock from "layouts/Main/MainBlock";
import MainMint from "layouts/Main/MainMint";
import MainSocialInformation from "layouts/Main/MainSocialInformation";
import MainPartners from "layouts/Main/MainPartners";
import MainCollections from "layouts/Main/MainCollections";
import MainNumbers from "layouts/Main/MainNumbers";
import MainRoadMap from "layouts/Main/MainRoadMap";
import MainCommunity from "layouts/Main/MainCommunity";
import MainFaq from "layouts/Main/MainFaq";

export default function Home({t}) {

	return (
		<>
			<Head>
				<script async src="https://platform.twitter.com/widgets.js" charSet="utf-8"/>
			</Head>
			<Seo
				title={t.seo.title}
				description={t.seo.description}
				image="/pic/og.jpg"
			/>
			<MainBlock t={t.main_block}/>
			<MainMint t={t.mint_live}/>
			<MainSocialInformation t={t.social_information}/>
			<MainPartners t={t.partners}/>
			<MainCollections t={t.collections}/>
			<MainNumbers t={t.numbers}/>
			<MainRoadMap/>
			<MainCommunity t={t.community}/>
			<MainFaq/>
		</>
	)
}

export async function getStaticProps({locale}) {
	return { props: {t: {...require(`locale/main/${locale}.json`)}} }
}
