import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Title from "components/Title";

export default function MainCommunity({t}) {
	return (
		<Wrapper>
			<CenterBlock>
				<div className="box">
					<Title><h2>Join our community</h2></Title>
					<div className="list">
						{
							t.list.map((item, index) => {
								return (
									<div className="item" key={index}>
										<div className="heading">
											<div className="icon">
												<img src={item.icon} alt={item.title}/>
											</div>
											<h3>{item.title}</h3>
										</div>
										<p className="description">{item.description}</p>
										<a href={item.link} target="_blank" rel="noreferrer">Join</a>
									</div>
								)
							})
						}
					</div>
				</div>
			</CenterBlock>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	margin-top: 120px;
	@media only screen and (max-width: 600px) {
		margin-top: 70px;
	}
	.box{
		border-radius: 60px;
		padding: 30px 60px;
		background: url("/pic/community-background.jpg");
		background-size: cover;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@media only screen and (max-width: 1024px) {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
		}
		@media only screen and (max-width: 600px) {
			padding: 24px;
			border-radius: 24px;
		}
	}
	.title{
		width: 396px;
		@media only screen and (max-width: 1024px) {
			width: 100%;
			text-align: center;
		}
		@media only screen and (max-width: 600px) {
			text-align: left;
		}
	}
	.list{
		width: calc(100% - 396px);
		padding-left: 40px;
		display: flex;
		justify-content: space-between;
		@media only screen and (max-width: 1024px) {
			width: 100%;
			margin-top: 40px;
			padding-left: 0;
		}
		@media only screen and (max-width: 600px) {
			flex-direction: column;
			justify-content: flex-start;
		}
	}
	.item{
		width: calc(100% / 2 - 10px);
		padding: 30px;
		background: #40BFFF;
		border-radius: 40px;
		@media only screen and (max-width: 600px) {
			width: 100%;
			background: transparent;
			border-radius: 0;
			margin-bottom: 30px;
			padding: 0 0 30px 0;
			border-bottom: 1px solid #02B3E8;
			&:last-child{
				margin-bottom: 0;
			}
		}
	}
	.heading{
		display: flex;
		align-items: center;
	}
	h3{
		font-size: 24px;
		line-height: 140%;
		margin-left: 16px;
		@media only screen and (max-width: 600px) {
			margin-left: 10px;
		}
	}
	.description{
		margin-top: 22px;
		font-size: 20px;
		line-height: 140%;
		color: #D6F2FF;
		@media only screen and (max-width: 600px) {
			font-size: 18px;
			line-height: 140%;
		}
	}
	a{
		background: #FFFFFF;
		border-radius: 16px;
		height: 65px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 24px;
		line-height: 140%;
		color: #0092DB;
		margin-top: 24px;
		@media only screen and (max-width: 600px) {
			font-size: 18px;
			line-height: 140%;
			height: 50px;
		}
	}
	.icon{
		width: 34px;
	}
`