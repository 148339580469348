import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Link from "next/link";
import Image from "next/image";
import htmlContent from "helpers/htmlContent";

export default function MainBlock({t}) {
	return (
		<Wrapper>
			<CenterBlock>
				<div className="mobile_coins">
					<Image src="/pic/mobile-coins.png" width={356} height={431} priority={true} alt="Coin Mobile"/>
				</div>
				<div className="content">
					<h1>{htmlContent(t.title)}</h1>
					<p className="description">{t.description}</p>
					{/*<div className="link">*/}
					{/*	<Link href="/marketplace">{t.market_button}</Link>*/}
					{/*</div>*/}
				</div>
				<div className="image">
					<div className="coin1">
						<Image src="/pic/coin-1.png" width={170} height={168} priority={true} alt="Coin 1"/>
					</div>
					<div className="coin2">
						<Image src="/pic/coin-2.png" width={176} height={168} priority={true} alt="Coin 2"/>
					</div>
					<div className="coin3">
						<Image src="/pic/coin-3.png" width={181} height={166} priority={true} alt="Coin 3"/>
					</div>
					<div className="main_pic">
						<Image src="/pic/main-pic.png" width={584} height={946} priority={true} alt="The most hyped NFT collection on Tron blockchain"/>
					</div>
					<div className="glow"><img src="/pic/main-glow.svg" alt="BAYCTron Glow"/></div>
				</div>
			</CenterBlock>
			<div className="overlay"/>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	min-height: 866px;
	position: relative;
	overflow: hidden;
	@media only screen and (max-width: 600px) {
		min-height: 400px;
	}
	.overlay{
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background: linear-gradient(180deg, rgba(11, 11, 25, 0) 0%, #0B0B19 93.33%);
		height: 443px;
		z-index: 10;
	}
	.mobile_coins{
		position: absolute;
		display: none;
		z-index: 30;
		top: 0;
		@media only screen and (max-width: 600px) {
			display: block;
		}
	}
	.main_pic{
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		@media only screen and (max-width: 820px) {
			&:before{
				content: "";
				position: absolute;
				width: calc(100% + 20px);
				height: 100%;
				top: 0;
				left: 0;
				background: rgba(11, 11, 25, 0.7);
				z-index: 10;
			}
		}
	}
	.coin2{
		position: absolute;
		bottom: 0;
		margin-bottom: 100px;
		right: -100px;
		z-index: 30;
		@media only screen and (max-width: 600px) {
			display: none;
		}
	}
	.coin3{
		position: absolute;
		right: -100px;
		top: 50px;
		z-index: 5;
		@media only screen and (max-width: 820px) {
			z-index: 30;
		}
		@media only screen and (max-width: 600px) {
			display: none;
		}
	}
	.coin1{
		position: absolute;
		left: -100px;
		top: 50%;
		z-index: 30;
		transform: translateY(-50%);
		@media only screen and (max-width: 1024px) {
			margin-top: 300px;
		}
		@media only screen and (max-width: 600px) {
			display: none;
		}
	}
	.glow{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) scale(2);
		width: 100%;
	}
	.center_block{
		padding-top: 150px;
		@media only screen and (max-width: 600px) {
			padding-top: 190px;
		}
	}
	h1{
		font-size: 80px;
		line-height: 140%;
		color: #FEFEFE;
		@media only screen and (max-width: 600px) {
			font-size: 60px;
			line-height: 140%;
			text-align: center;
		}
		span{
			color: #E1001C;
		}
	}
	.description{
		font-size: 24px;
		line-height: 140%;
		color: #CECED0;
		max-width: 351px;
		width: 100%;
		margin-top: 8px;
		@media only screen and (max-width: 600px) {
			text-align: center;
			font-size: 18px;
			line-height: 140%;
			color: #ffffff;
		}
	}
	.link{
		margin-top: 107px;
		a{
			display: inline-flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			background: #CE1329;
			border-radius: 22px;
			padding: 20px 77px;
			height: 80px;
			font-size: 24px;
			line-height: 140%;
			color: #ffffff;
			@media only screen and (max-width: 600px) {
				padding: 20px;
				width: 100%;
				font-size: 18px;
				line-height: 140%;
				height: 60px;
				border-radius: 16px;
				background: #E2EE44;
				color: #0B0B19;
			}
		}
	}
	.content{
		position: relative;
		z-index: 50;
	}
	.image{
		width: 584px;
		position: absolute;
		top: -80px;
		right: 0;
		height: 946px;
	}
`
