import styled from "styled-components";
import CenterBlock from "helpers/CenterBlock";
import Title from "components/Title";

export default function MainNumbers({t}) {
	return (
		<Wrapper>
			<CenterBlock>
				<div className="banner">
					<Title><h2>{t.title}</h2></Title>
					<div className="list">
						{
							t.list.map((item, index) => {
								return (
									<div className="item" key={index}>
										<div className="title_item">{item.title}</div>
										<p className="description">{item.description}</p>
									</div>
								)
							})
						}
					</div>
				</div>
			</CenterBlock>
		</Wrapper>
	)
}

const Wrapper = styled.section`
	margin-top: 120px;
	@media only screen and (max-width: 600px) {
		margin-top: 70px;
	}
	.banner{
		position: relative;
		overflow: hidden;
		background: #CE1329 url("/pic/numbers-background.jpg") no-repeat center right;
		background-size: cover;
		border-radius: 60px;
		padding: 80px 60px;
		@media only screen and (max-width: 600px) {
			padding: 80px 30px;
			border-radius: 24px;
			background: linear-gradient(90deg, #C23430 0%, #7E1A40 100%);
			background-size: contain;
		}
	}
	.title{
		@media only screen and (max-width: 600px) {
			text-align: center;
		}
	}
	.list{
		display: flex;
		flex-wrap: wrap;
		margin-right: -20px;
		margin-bottom: -20px;
		margin-top: 40px;
	}
	.item{
		width: calc(100% / 3 - 20px);
		margin-right: 20px;
		margin-bottom: 20px;
		border-radius: 36px;
		background: rgba(150, 19, 19, 0.6);
		backdrop-filter: blur(20px);
		padding: 60px 40px;
		@media only screen and (max-width: 820px) {
			width: calc(100% / 2 - 20px);
		}
		@media only screen and (max-width: 600px) {
			width: 100%;
			text-align: center;
			padding: 24px 50px;
			border-radius: 24px;
		}
		.title_item{
			font-size: 40px;
			line-height: 140%;
			font-weight: bold;
			font-family: "Halvar Breitschrift", sans-serif;
			@media only screen and (max-width: 600px) {
				font-size: 30px;
				line-height: 140%;
			}
		}
	}
	.description{
		font-size: 24px;
		line-height: 140%;
		color: #F7D3D1;
		@media only screen and (max-width: 600px) {
			font-size: 18px;
			line-height: 140%;
		}
	}
`