import {NextSeo} from 'next-seo';
import { useRouter } from "next/router";

export default function Seo({title, description, image, type = 'website', keywords = "", locale = 'en_IE'}) {
	const {asPath} = useRouter()
	const slug = process.env.WEB_ROUTE
	const titleHead = 'BAYCTron'

	return (
		<NextSeo
			title = {`${titleHead} | ${title}`}
			description = {description}
			keywords = {keywords}
			openGraph = {{
				url: `${slug}${asPath}`,
				title: `${title} | ${titleHead}`,
				description: description,
				type: type,
				site_name: titleHead,
				locale: locale,
				images: [
					{
						url: image.search('http') !== -1 ? image : image ? `${slug}${image}` : "",
						width: 1024,
						height: 512,
						alt: title,
					}
				]
			}}
		/>
	)
}
