import styled from "styled-components";

export default function LiveAnimation() {
	return (
		<Wrapper className="live_animation"/>
	)
}

const Wrapper = styled.span`
	width: 11px;
	height: 11px;
	background: #21ED00;
	border-radius: 50%;
	display: block;
	position: relative;
	margin-top: 5px;
	animation: animation_dot 2s linear infinite;
	&:before{
		content: "";
		position: absolute;
		background: rgba(33, 237, 0, 0.5);
		border-radius: 50%;
		width: 24px;
		height: 24px;
		top: 50%;
		left: 50%;
		animation: animation_blur 2s linear infinite;
	}
	@keyframes animation_dot{
		0%{
			background: #000;
		}
		50%{
			background: #21ED00;
		}
		100%{
			background: #000;
		}
	}
	@keyframes animation_blur{
		0%{
			opacity: 0;
			transform: translate(-50%, -50%) scale(.8);
		}
		50%{
			opacity: 1;
			transform: translate(-50%, -50%) scale(1);
		}
		100%{
			opacity: 0;
			transform: translate(-50%, -50%) scale(.8);
		}
	}
`